import React from "react";
import "./Services.scss";
import { images } from "../../constants";

import { motion } from "framer-motion";

const Services = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      className="app__services"
    >
      <div
        whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__services-head"
      >
        <h2>Our Services</h2>
        <p>"Make Your Car Run On The Road"</p>
      </div>

      <motion.div
        whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__services-card"
      >
        <div
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          className="services-card"
        >
          <div className="card-img">
            <img src={images.generalser} alt="engine-service" />
          </div>
          <div className="card-p">
            <h3>General Services</h3>
            <ul>
              <li>Engine Service</li>
              <li>Oil Change</li>
              <li>Brake Pads</li>
            </ul>
          </div>
        </div>

        <div
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          className="services-card services-card-rev"
        >
          <div className="card-p">
            <h3>Tyre Services</h3>
            <ul>
              <li>Tyre Change</li>
              <li>Wheel Alignment</li>
              <li>Balancing</li>
            </ul>
          </div>

          <div className="card-img tyre-img">
            <img src={images.tyreser} alt="tyre-service" />
          </div>
        </div>

        <div
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          className="services-card"
        >
          <div className="card-img">
            <img src={images.polishingser} alt="polishing-service" />
          </div>

          <div className="card-p">
            <h3>Coloring and Polishing</h3>
            <ul>
              <li>Denting & Painting</li>
              <li>Dynamic</li>
              <li>Ceramic</li>
              <li>Wax Polish</li>
              <li>Engine Cleaning</li>
            </ul>
          </div>
        </div>

        <div
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          className="services-card services-card-rev"
        >
          <div className="warranty-txt">
            <div className="card-p card-p-warranty">
              <h3>Warranty</h3>
              <p className="p-warranty">1 year warranty or 10000km</p>
              <p>(Which ever is early)</p>
            </div>
            <div className="card-p card-p-warranty">
              <h3>Buy Back</h3>
              <p>Under 1 year 20%</p>
              <p>Above 1 year 30%</p>
            </div>
          </div>

          <div className="warranty-img">
            <img src={images.warranty} alt="warranty-img" />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Services;
