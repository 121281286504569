import React from "react";
import Brands from "../../components/Brands/Brands";
import "./About.scss";

import { motion } from "framer-motion";

const About = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      className="app__about"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className="coverimg"
      ></motion.div>
      <motion.div
        whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__about-main"
      >
        <h2 className="since-h">
          Driving Dreams, Connecting Deals: Your Ultimate Car Marketplace
        </h2>

        <motion.div
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          className="app__about-content"
        >
          <div className="app__about-cards">
            <p>15000+</p>
            <p className="card-top">Yearly Visitors</p>
          </div>
          <div className="app__about-cards cards-2">
            <p>20000+</p>
            <p className="card-top">Vehicle Sales</p>
          </div>
        </motion.div>

        <h2 className="since-h sinceh2">
          Established in the Year 2002, Love Kush Cars in Udaipur City. Listed
          under best Used-Second hand cars Dealer in Udaipur, Raj. We have
          15,000+ yearly visitors and 20,000+ Vehicle Sales.
        </h2>
        <div className="about-brand">
          <Brands />
        </div>

        <motion.div
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          className="about-values"
        >
          <h2>Our Core Values</h2>
          <div className="values-boxes">
            <div className="value-box">
              <h3>Agility</h3>
              <p>We are quite flexible and open-minded</p>
            </div>
            <div className="value-box">
              <h3>Respect</h3>
              <p>We hold our coworkers, clients, and partners in high regard</p>
            </div>
            <div className="value-box">
              <h3>Ownership</h3>
              <p>Your Satisfaction is our responsibility</p>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default About;
