import logo from "../assets/carlogo.png";
import jeep from "../assets/jeep.jpg";
import about from "../assets/about.png";
import contact from "../assets/contact.png";
import generalser from "../assets/generalservices.jpg";
import tyreser from "../assets/tyreservices.jpg";
import polishingser from "../assets/polishingservices.jpg";
import warranty from "../assets/warranty.jpg";
import ig from "../assets/ig.png";
import fb from "../assets/fb.png";

export default {
  logo,
  jeep,
  about,
  contact,
  generalser,
  tyreser,
  polishingser,
  warranty,
  ig,
  fb,
};
