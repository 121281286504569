import React, { useState } from "react";
import { HiMenu, HiX } from "react-icons/hi";
import { easeInOut, easeOut, motion } from "framer-motion";
import "./Navbar.scss";
import { images } from "../../constants";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="app__navbar-main">
      <div className="app__navbar">
        <div className="app__navbar-desktop">
          <div className="app__navbar-img">
            <a href="/">
              <img src={images.logo} alt="logo" />
            </a>
          </div>
          <div className="app__navbar-text">
            <nav className="app__navbar-nav">
              <li key={"home-link"}>
                <div className="nav-dot" />
                <NavLink to={"/"} className={"nav-names"}>
                  HOME
                </NavLink>
              </li>
              {["about", "services", "contact"].map((item) => (
                <li key={`${item}-link`}>
                  <div className="nav-dot" />
                  <NavLink to={`/${item}`} className={"nav-names"}>
                    {item.toUpperCase()}
                  </NavLink>
                </li>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="app__mob-navbar">
        <div className="app__navbar-img-mob">
          <a href="/">
            <img src={images.logo} alt="logo" />
          </a>
        </div>

        <div className="app__navbar-mobile">
          <HiMenu className="" onClick={() => setToggle(true)} />
          {toggle && (
            <motion.div
              transition={{ duration: 0.4, ease: "easeOut" }}
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "100%", opacity: 0 }}
            >
              {/* <nav></nav> */}
              <HiX onClick={() => setToggle(false)} />
              <ul>
                <li key={"home"}>
                  <NavLink
                    to={"/"}
                    className={"nav-names-mob"}
                    onClick={() => setToggle(false)}
                  >
                    HOME
                  </NavLink>
                </li>
                {["about", "services", "contact"].map((item) => (
                  <li key={item}>
                    <NavLink
                      to={`/${item}`}
                      className={"nav-names-mob"}
                      onClick={() => setToggle(false)}
                    >
                      {item.toUpperCase()}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
