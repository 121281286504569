import React from "react";
import "./Search.scss";

import { motion } from "framer-motion";
import { urlFor } from "../../client";

const Search = ({ searchO }) => {
  return (
    <div className="search-cards">
      {searchO.map((data) => (
        <motion.div
          whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
          transition={{ duration: 0.5 }}
          className="search-cards-card"
        >
          <img src={urlFor(data.imageUrl)} alt={`${data.name}-img`} />
          <p className="feature-p">{data.name}</p>
          <p>{data.pord}</p>
          <p>{data.year}</p>
        </motion.div>
      ))}
    </div>
  );
};

export default Search;
