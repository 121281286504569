import React from "react";
import "./Footer.scss";
import { images } from "../../constants";

const Footer = () => {
  return (
    <div className="app__footer">
      <div className="app__footer-line"></div>
      <div className="app__footer-content">
        <div className="social">
          <a href="">
            <img src={images.ig} alt="insta-logo" />
          </a>
          <a href="">
            <img src={images.fb} alt="fb-logo" />
          </a>
        </div>
        <div className="contact">
          <h2>Contact Us</h2>
          <p>lovekush66827@gmail.com</p>
          <p>+91 9876543210</p>
          <p>www.lovekushcars.com</p>
        </div>
        <div className="address">
          <h2>Address</h2>
          <p>
            100 Feet Rd, opp. Mangalam Palace, New Ashok Vihar, Shobhagpura,
            Udaipur, Rajasthan 313001
          </p>
        </div>
      </div>
      <div className="app__footer-line-2"></div>
      <div className="footer-copyright">
        <p>@2023 DSpace. All rights reserved</p>
      </div>
    </div>
  );
};

export default Footer;
