import React, { useEffect, useState } from "react";
import "./Filter.scss";

import { motion } from "framer-motion";
import { urlFor, client } from "../../client";

const Filter = () => {
  const [card, setcards] = useState([]);
  const [cardFilter, setcardFilter] = useState([]);
  const [activeFilter, setActiveFilter] = useState("");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    const query = "*[_type == 'cards']";

    client.fetch(query).then((data) => {
      setcards(data);
      setcardFilter([]);
    });
  }, []);

  const handleFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      setcardFilter(card.filter((car) => car.tags.includes(item)));
    }, 200);
  };

  return (
    <div className="app__home-filter">
      <div className="filter-header">
        <p>Choose car according to your budget</p>
        <div className="filter-budgets">
          {[
            "1 Lakh - 5 Lakh",
            "5 Lakh - 15 Lakh",
            "15 Lakh - 25 Lakh",
            "25 Lakh - 35 Lakh",
            "35 Lakh - 45 Lakh",
            "45 Lakh - 55 Lakh",
          ].map((item) => (
            <button
              onClick={() => handleFilter(item)}
              className={`${activeFilter === item ? "item-active" : ""}`}
            >
              {item}
            </button>
          ))}
        </div>
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="filter-cards"
      >
        {cardFilter.map((data) => (
          <motion.div
            whileInView={{ scale: [0.9, 1] }}
            transition={{ duration: 0.5 }}
            className="filter-cards-card"
          >
            <img src={urlFor(data.imageUrl)} alt={`${data.name}-img`} />
            <p>{data.name}</p>
            <p>{data.rto}</p>
            <p>{data.year}</p>
            <p>{data.run}km</p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default Filter;
