import React, { useState, useEffect, useRef } from "react";
import Brands from "../../components/Brands/Brands";
import Filter from "../../components/Filter/Filter";

import { motion } from "framer-motion";
import "./Home.scss";
import { urlFor, client } from "../../client";
import Search from "../../components/Search/Search";

const Home = () => {
  const [featured, setfeatured] = useState([]);
  const [card, setCards] = useState([]);
  const [search, setSearch] = useState([]);

  useEffect(() => {
    const query = "*[_type == 'featured']";
    const all = "*[_type == 'cards']";

    client.fetch(query).then((data) => {
      setfeatured(data);
    });

    client.fetch(all).then((data) => {
      setCards(data);
      setSearch([]);
    });
  }, []);

  const ref = useRef(null);

  const handleSearch = () => {
    let searchV = document.querySelector(".home-search").value.toLowerCase();

    if (searchV) {
      setTimeout(() => {
        setSearch(card.filter((car) => car.tags.includes(searchV)));

        ref.current?.scrollIntoView({ behavior: "smooth" });

        document.querySelector(".search-name").innerHTML =
          "Showing results for : " + searchV;
      }, 200);

      document.getElementById("no-results").innerHTML = "No results found";

      document.querySelector(".again-btn").style.display = "flex";
    } else {
      window.alert("Kindly enter name to search");
    }

    document.querySelector(".home-search").value = "";
  };

  useEffect(() => {
    if (search.length > 0) {
      document.getElementById("no-results").style.display = "none";
    } else {
      document.getElementById("no-results").style.display = "flex";
    }
  }, [search]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      className="app__home"
    >
      <div className="app__home-hero">
        <div className="app__home-search-main">
          <p>FIND</p>
          <input type="text" name="" id="" className="home-search" />
          <button onClick={handleSearch} className="home-btn">
            Search
          </button>
        </div>
      </div>

      <Brands title={"Popular Brands"} />
      <div ref={ref} className="app__home-search">
        <h3 className="search-name"></h3>

        <Search searchO={search} />

        <h3 id="no-results"></h3>

        <button onClick={scrollToTop} className="again-btn">
          Search Again
        </button>
      </div>
      <Filter />
      <div className="app__home-featured">
        <h1>Featured Cars</h1>
        <motion.div
          transition={{ duration: 0.5, delayChildren: 0.5 }}
          className="feature-cards"
        >
          {featured.map((data) => (
            <motion.div
              whileInView={{ scale: [0.9, 1] }}
              transition={{ duration: 0.5 }}
              className="feature-cards-card"
            >
              <img src={urlFor(data.imageUrl)} alt={`${data.name}-img`} />
              <p className="feature-p">{data.name}</p>
              <p>{data.rto}</p>
              <p>{data.year}</p>
              <p>{data.run}km</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Home;
