import React from "react";
import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="app__notfound">
      <h1>404</h1>
      <p>The page you're looking for cannot be found.</p>
      <p>
        Go back to <a href="/">homepage</a> or <a href="/contact">contact us</a>{" "}
        about a problem.
      </p>
    </div>
  );
};

export default NotFound;
