import React, { useRef } from "react";
import "./Contact.scss";
import { images } from "../../constants";

import { motion } from "framer-motion";
import { FaLocationDot } from "react-icons/fa6";
import { BiSolidContact } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_3xb3m2l",
        "template_6ure1mr",
        form.current,
        "lhIA1ve6QDPKedv3a"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      className="app__contact"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className="coverimg"
      ></motion.div>
      <motion.div
        whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 0.5 }}
        className="contact-content"
      >
        <div className="content-left">
          <div>
            <p className="p-icon">
              <FaLocationDot className="ico" /> <span>Address</span>
            </p>

            <p>
              100 Feet Rd, opp. Mangalam Palace, New Ashok Vihar, Shobhagpura,
              Udaipur, Rajasthan 313001
            </p>
          </div>
          <div>
            <p className="p-icon">
              <BiSolidContact className="ico" /> <span>Contact No.</span>
            </p>

            <p>9694266827</p>
            <p>9784949843</p>
            <p>9414166827</p>
          </div>

          <div className="social">
            <p className="p-icon">
              <MdEmail className="email-ico" />
              <span>lovekush66827@gmail.com</span>
            </p>
            <a href="">
              <img src={images.ig} alt="insta-logo" />
            </a>
            <a href="">
              <img src={images.fb} alt="fb-logo" />
            </a>
          </div>
        </div>
        <div className="content-right">
          <h3>Connect with us</h3>
          <form ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              required="true"
              name="user_name"
              placeholder="Name"
            />

            <input
              type="email"
              required="true"
              name="user_email"
              placeholder="Email"
            />

            <input
              type="number"
              required="true"
              name="user_phone"
              placeholder="Phone"
            />

            <input type="text" placeholder="Car you want" name="user_choice" />

            <input
              type="text"
              required="true"
              placeholder="City"
              name="user_city"
            />

            <textarea
              name="message"
              placeholder="Leave your message"
              rows="4"
              cols="50"
            />

            <button className="btn" type="submit">
              SEND
            </button>
          </form>
        </div>
      </motion.div>
      <motion.div
        whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 0.5 }}
        className="contact-map"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d453.46856752151314!2d73.709705!3d24.597873!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3967e5841fa5b397%3A0x15e42d08e4373148!2sLove%20Kush%20Cars!5e0!3m2!1sen!2sin!4v1694533864799!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </motion.div>
    </motion.div>
  );
};

export default Contact;
