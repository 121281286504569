import React, { useEffect, useState } from "react";
import "./Brands.scss";

import { motion } from "framer-motion";
import { urlFor, client } from "../../client";

const Brands = ({ title }) => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const query = "*[_type == 'brands']";

    client.fetch(query).then((data) => {
      setBrands(data);
    });
  }, []);

  return (
    <div className="app__home-logos">
      <p className="brand-title">{title}</p>

      <div className="app__brands-cards">
        <div className="app__brand-section">
          {brands.map((brand) => (
            <motion.div
              className="brand-card"
              transition={{ duration: 0.2, ease: "easeOut" }}
              initial={{ x: "150%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "100%", opacity: 0 }}
            >
              <img src={urlFor(brand.imageUrl)} alt={`${brand.name}-img`} />
              <p>{brand.brandName}</p>
            </motion.div>
          ))}
        </div>

        <div className="app__brand-section">
          {brands.map((brand) => (
            <motion.div
              className="brand-card"
              transition={{ duration: 0.2, ease: "easeOut" }}
              initial={{ x: "150%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "100%", opacity: 0 }}
            >
              <img src={urlFor(brand.imageUrl)} alt={brand.name} />
              <p>{brand.brandName}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Brands;
